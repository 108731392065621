import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const BlockContentImage = (props) => {
  const data = useStaticQuery(graphql`
    query BlockContentImageQuery {
      image: sanityImageAsset(
        _id: {
          eq: "image-4f24894eda61331ecd2360f01597d83713750f3d-4752x3168-jpg"
        }
      ) {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
  `);

  return (
    <div className="page-image mb-6">
      <Img className="w-full" fluid={data.image.fluid} />
      {!props.node.caption || (
        <figcaption className="text-center mt-2 opacity-75 font-light text-base italic">
          {props.node.caption}
        </figcaption>
      )}
    </div>
  );
};

BlockContentImage.propTypes = {
  node: PropTypes.shape({
    image: PropTypes.object.isRequired,
    caption: PropTypes.string,
  }),
};

export default BlockContentImage;
